#home {
    position: relative;

    .app__wrapper {
        padding: 0;

        .copyright {
            display: none;
        }
    }
}

.app__header {

    width: 100%;
    height: 100%;
    flex-direction: row;
    padding: 0;

    @media screen and (min-width: 2000px){
        padding-top: 4rem;
        
    }

    @media screen and (max-width: 900px){
        padding-top: 4rem;
        flex-direction: column;
        
    }

    @media screen and (max-width: 450px){
        padding: 6rem 1rem 2rem;
        
    }
}

.home-text{
    color: #fff;
}

.app__header-circles-left{
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: flex-start;
    height: 100%;
    margin-left: 4rem;

    div{
        width: 100px;
        height: 100px;
        border-radius: 50%;

        background: rgba(255, 255, 255, 0.15);
        box-shadow: 0 0 20px rgba(0,0, 0, 0.1);

        img {
            width: 60%;
            height: 60%;
        }
    }

    div:nth-child(1) {
        margin-left: 9rem;
        width: 100px;
        height: 100px;
    }
    div:nth-child(2) {
        
        width: 150px;
        height: 150px;
    }
    div:nth-child(3) {
        margin-left: auto;
        width: 70px;
        height: 70px;
    }

    @media screen and (max-width: 900px) {
        align-items: flex-end;

        div:nth-child(1) {
            width: 100px;
            height: 100px;
            margin: 0;
        }
        div:nth-child(2) {
            width: 125px;
            height: 125px;
            margin: 0 0 1.5rem 0;
        }
        div:nth-child(3) {
            width: 100px;
            height: 100px;
            margin: 0;
            padding: 0;
        }
        
    }

    @media screen and (max-width: 450px){

        div:nth-child(1) {
            width: 80px;
            height: 80px;
        }
        div:nth-child(2) {
            width: 100px;
            height: 100px;
        }
        div:nth-child(3) {
            width: 80px;
            height: 80px;
        }
    }

    @media screen and (min-width: 2000px) {

        div:nth-child(1) {
            // margin: 0;
            width: 250px;
            height: 250px;
        }
        div:nth-child(2) {
            width: 170px;
            height: 170px;
        }
        div:nth-child(3) {
            // margin: 0;
            width: 200px;
            height: 200px;
        }
        
    }

    @media screen and (min-width: 3000px) {

        div:nth-child(1) {
            // margin-right: 5rem;
            width: 400px;
            height: 400px;
        }
        div:nth-child(2) {
            width: 250px;
            height: 250px;
        }
        div:nth-child(3) {
            // margin-right: auto;
            width: 350px;
            height: 350px;
        }
        
    }

    @media screen and (max-width: 900px) {
        width: 100%;
        flex-direction: row;
        flex-wrap: wrap;
        margin-left: 0;

        div {
            margin: 1rem;
        }
    }
}

.app__header-circles-right{
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: flex-end;
    height: 100%;
    margin-right: 5rem;

    div{
        width: 100px;
        height: 100px;
        border-radius: 50%;

        background: rgba(255, 255, 255, 0.15);
        box-shadow: 0 0 20px rgba(0,0, 0, 0.1);

        img {
            width: 60%;
            height: 60%;
        }
    }

    div:nth-child(1) {
        margin-right: 9rem;
        width: 100px;
        height: 100px;
    }
    div:nth-child(2) {
        width: 150px;
        height: 150px;
    }
    div:nth-child(3) {
        margin-right: auto;
        width: 70px;
        height: 70px;
    }

    @media screen and (max-width: 900px) {
        margin: 0;
        align-items: flex-start;

        div:nth-child(1) {
            width: 100px;
            height: 100px;
            margin: 0;
            padding: 0;
        }
        div:nth-child(2) {
            width: 125px;
            height: 125px;
            margin: 2rem 0 0 0;
            padding: 0;
        }
        div:nth-child(3) {
            width: 100px;
            height: 100px;
            margin: 0;
            padding: 0;
        }
        
    }

    @media screen and (max-width: 450px){

        margin-top: 0.5rem;

        div:nth-child(1) {
            width: 80px;
            height: 80px;
        }
        div:nth-child(2) {
            width: 100px;
            height: 100px;
        }
        div:nth-child(3) {
            width: 80px;
            height: 80px;
        }
    }

    @media screen and (min-width: 2000px) {
        margin-right: 5.8rem;
        div:nth-child(1) {
            
            width: 250px;
            height: 250px;
        }
        div:nth-child(2) {
            margin-right: 0.5rem;
            width: 170px;
            height: 170px;
        }
        div:nth-child(3) {
            // margin-right: auto;
            width: 200px;
            height: 200px;
        }
        
    }

    @media screen and (min-width: 3000px) {

        div:nth-child(1) {
            // margin-right: 5rem;
            width: 400px;
            height: 400px;
        }
        div:nth-child(2) {
            width: 250px;
            height: 250px;
        }
        div:nth-child(3) {
            // margin-right: auto;
            width: 350px;
            height: 350px;
        }
        
    }

    @media screen and (max-width: 900px) {
        width: 100%;
        flex-direction: row;
        flex-wrap: wrap;
        margin-left: 0;

        div {
            margin: 1rem;
        }
    }
}

.app__header-info{
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    width: 100%;
    

    @media screen and (max-width: 2000px) {
        width: 100%;
        margin-right: 0rem;
        
    }
}

.app__header-badge {
    width: 100%;
    display: flex;
    flex-direction: column;
    text-align: center;

    @media screen and (max-width: 900px) {
        justify-content: flex-start;
        align-items: center;
    }
}

.greeting-text{
    font-size: 4.5rem;

    @media screen and (max-width: 900px) {
        font-size: 3.25rem;
    }

    @media screen and (min-width: 2000px) {
        font-size: 5.5rem;
    }

    @media screen and (min-width: 3000px) {
        font-size: 7.5rem;
    }
}

.introduction-text{
    font-size: 3.5rem;

    @media screen and (max-width: 900px) {
        font-size: 2rem;
    }

    @media screen and (min-width: 2000px) {
        font-size: 4.5rem;
    }

    @media screen and (min-width: 3000px) {
        font-size: 5.5rem;
    }
}

.introduction-description-text{
    font-size: 1.5rem;

    @media screen and (max-width: 900px) {
        font-size: 1.25rem;
    }

    @media screen and (min-width: 2000px) {
        font-size: 2.5rem;
    }

    @media screen and (min-width: 3000px) {
        font-size: 3.5rem;
    }
}

.contact-button {
    padding: 1rem 2rem;
    display: inline-block;
    text-decoration: none;
    border: 1px solid #fff;
    border-radius: 10px;
    font-weight: 300;
    color: var(--white-color);
    margin: 2.5rem 0 0 0;
    font-family: var(--font-base);
    cursor: pointer;

    @media screen and (min-width: 2000px) {
        padding: 1rem 2.25rem;
        border-width: 2px;
        font-size: 1rem;
    }

    @media screen and (min-width: 3000px) {
        padding: 1.25rem 2.5rem;
        font-size: 1.25rem;
    }
    
}

.contact-button:hover {
    transition: 1s;
    background-color: var(--secondary-color);
}

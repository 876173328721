.app__testimonial {
    flex: 1;
    width: 100%;
    flex-direction: column;
  }
  
  .app__testimonial-item {
    width: 60%;
    min-height: 320px;
    background-color: var(--white-color);
    display: flex;
    flex-direction: row;
    padding: 2rem;
    border-radius: 15px;
    box-shadow: 0px 0px 30px rgba(0, 0, 0, 0.1);
  
    transition: all 0.3s ease-in-out;
  
    img {
      width: 100px;
      height: 100px;
      border-radius: 50%;
      object-fit: contain;
    }
  
    @media screen and (min-width: 2000px) {
      min-height: 400px;
  
      img {
        width: 125px;
        height: 125px;
      }
    }

    @media screen and (min-width: 3000px) {
      min-height: 600px;
  
      img {
        width: 175px;
        height: 175px;
      }
    }
  
    @media screen and (max-width: 1000px) {
      width: 100%;
    }
  
    @media screen and (max-width: 600px) {
      flex-direction: column;
    }
  }
  
  .app__testimonial-content {
    flex: 1;
    height: 100%;
    padding: 0 2rem;
    text-align: left;
  
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: flex-start;
  
    p {
      font-size: 1rem;
      line-height: 1.75rem;
      color: var(--black-color);
      font-family: var(--font-base);
  
      @media screen and (min-width: 2000px) {
        font-size: 1.75rem;
        line-height: 3rem;
      }

      @media screen and (min-width: 3000px) {
        font-size: 2.25rem;
        line-height: 3.75rem;
      }
    }
  
    h4 {
      font-weight: 600;
      color: var(--secondary-color);
      margin-top: 2rem;
    }
  
    h5 {
      font-weight: 400;
      color: var(--gray-color);
      margin-top: 5px;
    }
  
    @media screen and (max-width: 600px) {
      margin-top: 2rem;
      padding: 0;
    }
  }
  
  .app__testimonial-btns {
    flex-direction: row;
    margin-top: 1rem;
  
    div {
      width: 50px;
      height: 50px;
      border-radius: 50%;
      background-color: var(--white-color);
  
      margin: 1rem;
      transition: all 0.3s ease-in-out;
  
      svg {
        width: 20px;
        height: 20px;
        color: var(--secondary-color);
      }
  
      &:hover {
        background-color: var(--secondary-color);
  
        svg {
          color: var(--white-color);
        }
      }
  
      @media screen and (min-width: 2000px) {
        width: 80px;
        height: 80px;
  
        svg {
          width: 40px;
          height: 40px;
        }
      }

      @media screen and (min-width: 3000px) {
        width: 100px;
        height: 100px;
  
        svg {
          width: 45px;
          height: 45px;
        }
      }
    }
  }

  .app__testimonial-items{
    margin-top: 1.5rem;
    display: flex;
    flex-direction: row;
    justify-content: center;
    gap: 4rem;

    @media screen and (max-width: 1500px) {
      gap: 2rem;
    }

    @media screen and (max-width: 1000px) {
      display: none;
    }
  }

  .app__testimonial-item-small {
    
    width: 25%;
    height: 200px;
    background-color: var(--white-color);
    display: flex;
    flex-direction: row;
    padding: 1rem;
    border-radius: 15px;
    box-shadow: 0px 0px 30px rgba(0, 0, 0, 0.1);
  
    transition: all 0.3s ease-in-out;
  
    img {
      width: 75px;
      height: 75px;
      border-radius: 50%;
      object-fit:contain;

      @media screen and (max-width: 1500px) {
        width: 50px;
        height: 50px;
      }
    }
  
    @media screen and (min-width: 2000px) {
      min-height: 300px;
  
      img {
        width: 100px;
        height: 100px;
      }
    }

    @media screen and (min-width: 3000px) {
      min-height: 400px;
  
      img {
        width: 125px;
        height: 125px;
      }
    }

    @media screen and (max-width: 1500px) {
      padding: 0.5rem 0.75rem;
    }
  
    @media screen and (max-width: 600px) {
      flex-direction: column;
    }
  }

  .app__testimonial-content-small {
    flex: 1;
    height: 80%;
    
    padding: 0 1rem;
    text-align: left;
  
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: flex-start;
  
    p {
      overflow: hidden;
      font-size: 0.85rem;
      line-height: 1.25rem;
      color: var(--black-color);
      font-family: var(--font-base);

      @media screen and (max-width: 1500px) {
        font-size: 0.75rem;
        line-height: 1.1rem;
      }

      @media screen and (min-width: 2000px) {
        font-size: 1.25rem;
        line-height: 2rem;
      }

      @media screen and (min-width: 3000px) {
        font-size: 2rem;
        line-height: 3rem;
      }

    }
  
    h4 {
      font-weight: 550;
      color: var(--secondary-color);
      margin-top: 2rem;
    }
  
    h5 {
      font-weight: 375;
      color: var(--gray-color);
      margin-top: 5px;
    }
  
    @media screen and (max-width: 600px) {
      margin-top: 2rem;
      padding: 0;
    }

    @media screen and (max-width: 1500px) {
      height: 75%;
    }

    
  }